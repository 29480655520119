import AppLandingNotify, { LandingType } from 'components/common/AppLandingNotify';
import { useIsApp } from 'util/is_app';

/**
 * 홈 바텀바 위에 표시되는 띠배너
 * @component
 */
const LineBanner = () => {
  const is_app = useIsApp();

  if (is_app) {
    return null;
  }

  return <AppLandingNotify type={LandingType.NAVIGATION} />;
};

export default LineBanner;
