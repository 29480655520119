import { useUpdateAtom } from 'jotai/utils';
import React, { useEffect, PropsWithChildren } from 'react';
import LineBanner from '../LineBanner';
import LiveBanner from './LiveBanner';
import { isOpenAppLandingNavigationAtom } from 'components/common/AppLandingNotify/store/isOpenAppLandingNavigation';

import BottomBar from 'components/common/BottomTabBar';
import Layout from 'components/common/Layout';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { useLiveShop } from 'queries/live/useLiveShop';

interface Props extends PropsWithChildren {}

const HomeLayout: React.FC<Props> = ({ children }) => {
  const setIsOpenAppLandingNavigation = useUpdateAtom(isOpenAppLandingNavigationAtom);
  const { is_native_version } = useAppVersion();
  const { is_live } = useLiveShop();

  useEffect(() => {
    if (is_native_version) {
      return;
    }

    setIsOpenAppLandingNavigation((c) => {
      c.is_show = true;
    });

    return () => {
      setIsOpenAppLandingNavigation((c) => {
        c.is_show = false;
      });
    };
  }, [is_native_version, setIsOpenAppLandingNavigation]);

  return (
    <Layout>
      {children}
      {is_live ? <LiveBanner /> : <LineBanner />}
      {!is_native_version && <BottomBar />}
    </Layout>
  );
};
export default HomeLayout;
