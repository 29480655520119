import styled from '@emotion/styled';
import { ReactNode, useEffect, useState } from 'react';
import Link from 'components/base/Link';
import Tooltip from 'components/base/Tooltip';
import { ColorType, gray_active, gray_inactive, pink600 } from 'styles';
import { typography } from 'styles/font';

export interface MenuItemProps {
  title: string;
  is_selected: boolean;
  icon: ReactNode;
  tooltip_info?: {
    contents: ReactNode;
    tooltip_color?: ColorType;
    is_close_icon?: boolean;
    close_icon_color?: ColorType;
  };
  is_new?: boolean;
  href?: string;
  onClick?: () => void;
}

function MenuItem({ title, icon, tooltip_info, is_new, is_selected, href, onClick }: MenuItemProps) {
  const [is_tooltip, setIsTooltio] = useState<boolean>(false);

  useEffect(() => {
    if (tooltip_info !== undefined) {
      setIsTooltio(true);
    }
  }, [tooltip_info]);

  const handleCloseTooltip = () => {
    setIsTooltio(false);
    onClick?.();
  };

  return (
    <SC.Container is_selected={is_selected}>
      <Tooltip
        open={is_tooltip}
        is_close_icon={tooltip_info?.is_close_icon}
        close_icon_color={tooltip_info?.close_icon_color}
        close={handleCloseTooltip}
        contents={tooltip_info?.contents}
        tooltip_color={tooltip_info?.tooltip_color}
        motion_option={{ initial: { opacity: 0, y: 50 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.5 } }}
      >
        <Link classname='link' href={href} is_same_route={is_selected} replace aria-label={title} onClick={onClick}>
          {is_new && <SC.NewBadge />}
          {icon}
          {title}
        </Link>
      </Tooltip>
    </SC.Container>
  );
}
export default MenuItem;

const SC = {
  Container: styled.li<{ is_selected?: boolean }>`
    .link {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      ${typography.small3_bold}
      align-items: center;
      margin-bottom: 3px;
      color: ${({ is_selected }) => (is_selected ? gray_active : gray_inactive)};
    }

    svg {
      margin-top: 4px;
    }
  `,
  NewBadge: styled.div`
    position: absolute;
    top: 4px;
    right: -2px;
    background-color: ${pink600};
    width: 4px;
    height: 4px;
    border-radius: 4px;
  `,
};
