import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useState } from 'react';
import MenuItem, { MenuItemProps } from './MenuItem';
import {
  IconBottomCategoryN24,
  IconBottomCategoryP24,
  IconBottomHomeN24,
  IconBottomHomeP24,
  IconBottomLikeN24,
  IconBottomLikeP24,
  IconBottomMypageN24,
  IconBottomMypageP24,
  IconBottomStoreN24,
  IconBottomStoreP24,
} from 'components/base/Icon';
import { is_show_like_tab_new_badge_atom, IS_SHOW_LIKE_TAB_NEW_BADGE_KEY } from 'components/common/store/like';
import { web_path } from 'constants/web_path';
import { useMount } from 'hooks';
import { NAVIGATION_BAR_HEIGHT, WEB_MAX_WIDTH, gray100, white } from 'styles';
import { useIsApp } from 'util/is_app';

// MEMO: 스타일탭 뉴뱃지 처리를 위함 (api개발이 안되어 디바이스 기준정책으로 FE임시처리
// https://croquis.atlassian.net/browse/FBK-695
const IS_HIDE_STYLE_TAB_NEW_BADGE_KEY = 'IS_HIDE_STYLE_TAB_NEW_BADGE';
const IS_HIDE_CATEGORY_TAB_TOOLTIP_KEY = 'IS_HIDE_CATEGORY_TAB_TOOLTIP';

interface Props {
  classname?: string;
}

const BottomBar: FC<Props> = ({ classname }) => {
  const { route, back } = useRouter();
  const [is_show_style_tab_new_badge, setIsShowStyleTabNewBadge] = useState(false);
  const [is_show_like_tab_new_badge, setIsShowLikeTabNewBadge] = useAtom(is_show_like_tab_new_badge_atom);
  const is_native_app = useIsApp();

  useMount(() => {
    setIsShowStyleTabNewBadge(!localStorage.getItem(IS_HIDE_STYLE_TAB_NEW_BADGE_KEY));
  });

  useEffect(() => {
    // 찜탭 진입 시 뉴뱃지 비활성화
    const updateShowLikeTabNewBadge = () => {
      const is_show = route.includes('/like') ? false : localStorage.getItem(IS_SHOW_LIKE_TAB_NEW_BADGE_KEY) === 'true';
      setIsShowLikeTabNewBadge(is_show);
    };
    updateShowLikeTabNewBadge();

    // app 에서 새창 띄우고 뒤로가기 했을때 뱃지 업데이트
    if (is_native_app) {
      if (typeof window !== 'undefined') {
        window.addEventListener('visibilitychange', updateShowLikeTabNewBadge, false);
        window.addEventListener('focus', updateShowLikeTabNewBadge, false);
      }

      return () => {
        window.removeEventListener('visibilitychange', updateShowLikeTabNewBadge);
        window.removeEventListener('focus', updateShowLikeTabNewBadge);
      };
    }
  }, [is_native_app, route, setIsShowLikeTabNewBadge]);

  const bottom_menu_list: MenuItemProps[] = useMemo(
    () => [
      {
        is_selected: route === web_path.home || route === web_path.root || route === web_path.category_detail,
        icon:
          route === web_path.home || route === web_path.root || route === web_path.category_detail ? (
            <IconBottomHomeP24 />
          ) : (
            <IconBottomHomeN24 />
          ),
        title: '홈',
        href: route === web_path.category_detail ? undefined : web_path.root,
        onClick: () => {
          const is_category_page = route === web_path.category_detail;
          is_category_page && back();
        },
      },
      {
        is_selected: route === web_path.category_main,
        icon: route === web_path.category_main ? <IconBottomCategoryP24 /> : <IconBottomCategoryN24 />,
        title: '카테고리',
        href: web_path.category_main,
        onClick: () => {
          localStorage.setItem(IS_HIDE_CATEGORY_TAB_TOOLTIP_KEY, 'true');
        },
      },
      {
        is_selected: route === '/stores',
        icon: route === '/stores' ? <IconBottomStoreP24 /> : <IconBottomStoreN24 />,
        title: '스토어',
        is_new: is_show_style_tab_new_badge,
        href: '/stores',
        onClick: () => {
          localStorage.setItem(IS_HIDE_STYLE_TAB_NEW_BADGE_KEY, 'true');
        },
      },
      {
        is_selected: route === web_path.like,
        icon: route === web_path.like ? <IconBottomLikeP24 /> : <IconBottomLikeN24 />,
        title: '찜',
        is_new: is_show_like_tab_new_badge,
        href: web_path.like,
        onClick: () => {
          localStorage.setItem(IS_HIDE_STYLE_TAB_NEW_BADGE_KEY, 'true');
        },
      },
      {
        is_selected: route === web_path.mypage.root,
        icon: route === web_path.mypage.root ? <IconBottomMypageP24 /> : <IconBottomMypageN24 />,
        title: '마이페이지',
        href: web_path.mypage.root,
      },
    ],
    [route, is_show_like_tab_new_badge, is_show_style_tab_new_badge, back],
  );

  return (
    <SC.Container className={classname} aria-label='하단 네비게이션 메뉴'>
      <SC.MenuList>
        {bottom_menu_list.map((item) => (
          <MenuItem key={item.title} {...item} />
        ))}
      </SC.MenuList>
    </SC.Container>
  );
};
export default BottomBar;

const SC = {
  Container: styled.nav`
    background: ${white};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: ${WEB_MAX_WIDTH}px;
    width: 100%;
    margin: 0 auto;
    z-index: 310;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  `,
  MenuList: styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: ${NAVIGATION_BAR_HEIGHT}px;
    background: ${white};
    border-top: 1px solid ${gray100};
  `,
};
