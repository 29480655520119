import styled from '@emotion/styled';
import lottie from 'lottie-web';
import { useRef } from 'react';
import { IconArrowRight16 } from 'components/base/Icon';
import Text from 'components/base/Text';
import { useFbkRouter, useMount } from 'hooks';
import { useAppVersion } from 'hooks/app/useAppVersion';
import { useLiveShop } from 'queries/live/useLiveShop';
import { WEB_MAX_WIDTH, gray_tertiary, white } from 'styles';
import { useTracker } from 'util/Tracker';

const HOME_NAVIGATION_MAPPER: Record<string, string> = {
  best: 'home_best',
  sale: 'home_benefits',
  new: 'home_curation',
  trend: 'home_ad',
};

const MAINTAB_NAGIVATION_MAPPER: Record<string, string> = {
  '/categories': 'category_tab',
  '/style': 'style',
  '/like': 'saved_product',
  '/my-page': 'my_page',
};
/**
 * 라이브 띠 배너
 * @component
 */
const LiveBanner = () => {
  const live_animation_ref = useRef<HTMLDivElement>(null);
  const { is_native_version } = useAppVersion();
  const { is_live, live_info } = useLiveShop();
  const { goLive } = useFbkRouter();
  const tracker = useTracker();

  useMount(() => {
    if (live_animation_ref.current) {
      lottie.loadAnimation({
        container: live_animation_ref.current,
        autoplay: true,
        loop: true,
        animationData: require('./images/live.json'),
      });
    }
  });

  const handleLiveClick = () => {
    if (typeof window !== 'undefined') {
      tracker.addLog({
        category: 'click',
        navigation: getNavigation(),
        navigation_sub: { page_url: `${window.location.origin}/lives/${live_info?.campaign_key}` },
        object_section: 'bottom_floating_strip_banner',
        object_type: 'banner',
        object_id: '-1',
        object_idx: 0,
        data: {
          banner_title: 'live_strip_banner',
          component_idx: 100,
        },
      });
    }

    if (live_info) {
      goLive(live_info.campaign_key);
    }
  };

  if (!is_live) {
    return null;
  }

  return (
    <SC.Container is_native_app={is_native_version} onClick={handleLiveClick}>
      <div className='item'>
        <SC.Live ref={live_animation_ref} />
        <Text variant='body4_bold' color='gray900' ml={6}>
          {live_info?.title}
        </Text>
      </div>
      <div className='item'>
        <Text variant='small1_bold' color='gray_secondary' mr={2}>
          보기
        </Text>
        <IconArrowRight16 color={gray_tertiary} />
      </div>
    </SC.Container>
  );
};

export default LiveBanner;

function getNavigation() {
  if (typeof window === 'undefined') {
    return '';
  }

  const { pathname, href } = window.location;

  if (pathname === '/' || pathname === '/home') {
    const { search, searchParams } = new URL(href);
    const params = search ? Object.fromEntries(searchParams) : undefined;

    if (params?.tab) {
      return HOME_NAVIGATION_MAPPER[params.tab];
    }

    return HOME_NAVIGATION_MAPPER.trend;
  }

  const main_navigation_info = Object.entries(MAINTAB_NAGIVATION_MAPPER).find((mapper) =>
    pathname.startsWith(mapper[0]),
  );

  return main_navigation_info ? main_navigation_info[1] : '';
}

const SC = {
  Container: styled.div<{ is_native_app: boolean }>`
    cursor: pointer;
    padding: 12px 16px;
    background-color: ${white};
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: ${({ is_native_app }) => (is_native_app ? '0' : '48px')};
    width: 100%;
    max-width: ${WEB_MAX_WIDTH}px;
    z-index: 3;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .live_badge {
        font-weight: 900;
      }
    }
  `,
  Live: styled.div`
    width: 31px;
    height: 18px;
  `,
};
